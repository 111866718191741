import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Review.scss"; // Ensure this SCSS file matches the styles

const API_URL = process.env.REACT_APP_API_URL; // Fetch API URL from .env

function AddReview() {
  const [name, setName] = useState(""); // Reviewer name
  const [description, setDescription] = useState(""); // Review description
  const [image, setImage] = useState(null); // For handling the image
  const [reviews, setReviews] = useState([]); // All reviews
  const [editId, setEditId] = useState(null); // To handle editing of a specific review

  useEffect(() => {
    fetchReviews(); // Fetch reviews when the component mounts
  }, []);

  const fetchReviews = async () => {
    try {
      const response = await axios.get(`${API_URL}/reviews`);
      setReviews(response.data);
    } catch (error) {
      console.error("Error fetching reviews:", error.message);
      alert("Failed to fetch reviews. Please try again.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    if (image) {
      formData.append("image", image);
    }

    try {
      if (editId) {
        await axios.put(`${API_URL}/reviews/update/${editId}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        alert("Review updated successfully!");
      } else {
        await axios.post(`${API_URL}/reviews/add`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        alert("Review added successfully!");
      }
      fetchReviews(); // Refresh the review list
      resetForm(); // Reset the form after successful submission
    } catch (error) {
      console.error("Error saving review:", error.message);
      alert("Failed to save review. Please try again.");
    }
  };

  const handleFileChange = (e) => setImage(e.target.files[0]);

  const resetForm = () => {
    setName("");
    setDescription("");
    setImage(null);
    setEditId(null);
  };

  const handleEdit = (review) => {
    setName(review.name);
    setDescription(review.description);
    setEditId(review.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/reviews/delete/${id}`);
      fetchReviews(); // Refresh the review list after deletion
    } catch (error) {
      console.error("Error deleting review:", error.message);
      alert("Failed to delete review.");
    }
  };

  return (
    <div className="review-container">
      <div className="card-container">
        {/* Add/Update Review Card */}
        <div className="review-card-entry">
          <h2>{editId ? "Update Review" : "Add Review"}</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Name:</label>
              <input
                className="input-review"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                required
              />
            </div>

            <div>
              <label>Description:</label>
              <textarea
                className="input-review"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
                required
              ></textarea>
            </div>

            <div>
              <label>Image:</label>
              <input type="file" onChange={handleFileChange} />
            </div>

            <button className="button-add-review" type="submit">
              {editId ? "Update Review" : "Add Review"}
            </button>
          </form>
        </div>

        {/* Display Reviews Card */}
        <div className="review-card-display">
          <h2>Review List</h2>
          {reviews && reviews.length > 0 ? (
            reviews.map((review) => (
              <div key={review.id} className="review-display-item">
                <div className="review-info">
                  {review.image && (
                    <img
                      src={`${API_URL}${review.image}`}
                      alt={review.name}
                      className="review-image"
                    />
                  )}
                  <div>
                    <span className="review-name">{review.name}</span>
                  </div>
                  <div>
                    {" "}
                    <span className="review-description">
                      {review.description}
                    </span>
                  </div>
                </div>
                <div className="review-actions">
                  <button onClick={() => handleEdit(review)}>Edit</button>
                  <button onClick={() => handleDelete(review.id)}>
                    Delete
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No reviews found.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddReview;
