import React from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { FaBell, FaUserCircle, FaBars } from "react-icons/fa";
import "./AdminPanel.scss";
import logo from "../../assets/logoo.png";
import AddCategory from "../../Screens/AddCategory/AddCategory";
import AddProductCategory from "../../Screens/AddProductCategory/AddProductCategory";
import AddProduct from "../../Screens/AddProduct/AddProduct";
import AddReview from "../../Screens/AddReview/AddReview";
import AddNews from "../../Screens/AddNews/AddNews";
import Dashboard from "../../Screens/Dashboard/Dashboard";

function AdminPanel() {
  const handleLogout = () => {
    // Logic for logout
    localStorage.removeItem("auth");
    // Navigate to login page
  };

  const toggleSidebar = () => {
    // Logic to toggle the sidebar
    document.querySelector(".sidebar").classList.toggle("collapsed");
  };

  return (
    <div className="main-panel">
      <div className="admin-panel">
        <Sidebar />
        <div className="content">
          <div className="sticky-navbar">
            <div className="left-section">
              <button className="toggle-sidebar" onClick={toggleSidebar}>
                <FaBars />
              </button>
              <div className="logo-div">
                <img src={logo} className="logo-img" alt="Logo" />
              </div>
            </div>
            <div className="right-section">
              <span className="welcome-text">Dashboard</span>
              <FaBell className="icon" />
              <div className="user-profile">
                <FaUserCircle className="icon" />
                <div className="dropdown">
                  <button onClick={handleLogout}>Logout</button>
                </div>
              </div>
            </div>
          </div>

          <Routes>
            <Route path="add-category" element={<AddCategory />} />
            <Route path="add-cproduct" element={<AddProductCategory />} />
            <Route path="add-product" element={<AddProduct />} />
            <Route path="add-review" element={<AddReview />} />
            <Route path="add-news" element={<AddNews />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default AdminPanel;
