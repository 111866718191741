import React, { useState, useEffect } from "react";
import axios from "axios";
import "./News.scss"; // Ensure this SCSS file matches the styles

const API_URL = process.env.REACT_APP_API_URL; // Fetch API URL from .env

function AddNews() {
  const [title, setTitle] = useState(""); // News title
  const [date, setDate] = useState(""); // News date
  const [description, setDescription] = useState(""); // News description
  const [url, setUrl] = useState(""); // News URL
  const [image, setImage] = useState(null); // For handling the image
  const [newsList, setNewsList] = useState([]); // All news articles
  const [editId, setEditId] = useState(null); // To handle editing of a specific news article

  useEffect(() => {
    fetchNews(); // Fetch news when the component mounts
  }, []);

  const fetchNews = async () => {
    try {
      const response = await axios.get(`${API_URL}/news`);
      setNewsList(response.data);
    } catch (error) {
      console.error("Error fetching news articles:", error.message);
      alert("Failed to fetch news articles. Please try again.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("date", date);
    formData.append("description", description);
    formData.append("url", url);

    if (image) {
      formData.append("image", image);
    }

    try {
      if (editId) {
        await axios.put(`${API_URL}/news/update/${editId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("News updated successfully!");
      } else {
        await axios.post(`${API_URL}/news/add`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("News added successfully!");
      }
      fetchNews(); // Refresh the news list
      resetForm(); // Reset the form after successful submission
    } catch (error) {
      console.error("Error saving news:", error.message);
      alert("Failed to save news article. Please try again.");
    }
  };

  const handleFileChange = (e) => setImage(e.target.files[0]);

  const resetForm = () => {
    setTitle("");
    setDate("");
    setDescription("");
    setUrl("");
    setImage(null);
    setEditId(null);
  };

  const handleEdit = (news) => {
    setTitle(news.title);
    setDate(news.date);
    setDescription(news.description);
    setUrl(news.url);
    setEditId(news.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/news/delete/${id}`);
      fetchNews(); // Refresh the news list after deletion
    } catch (error) {
      console.error("Error deleting news article:", error.message);
      alert("Failed to delete news article.");
    }
  };

  return (
    <div className="news-container">
      <div className="card-container">
        {/* Add/Update News Card */}
        <div className="news-card-entry">
          <h2>{editId ? "Update News" : "Add News"}</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Title:</label>
              <input
                className="input-news"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="News Title"
                required
              />
            </div>

            <div>
              <label>Date:</label>
              <input
                className="input-news"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
              />
            </div>

            <div>
              <label>Description:</label>
              <textarea
                className="input-news"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="News Description"
              ></textarea>
            </div>

            <div>
              <label>URL:</label>
              <input
                className="input-news"
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="URL Link"
                required
              />
            </div>

            <div>
              <label>Image:</label>
              <input type="file" onChange={handleFileChange} />
            </div>

            <button className="button-add-news" type="submit">
              {editId ? "Update News" : "Add News"}
            </button>
          </form>
        </div>

        {/* Display News Card */}
        <div className="news-card-display">
          <h2>News List</h2>
          {newsList && newsList.length > 0 ? (
            newsList.map((news) => (
              <div key={news.id} className="news-display-item">
                <div className="news-info">
                  {news.image && (
                    <img
                      src={`${API_URL}${news.image}`}
                      alt={news.title}
                      className="news-image"
                    />
                  )}
                  <div>
                    <span className="news-title">{news.title}</span>
                  </div>
                  <div>
                    <span className="news-description">{news.description}</span>
                  </div>
                  <div>
                    <span className="news-url">URL: {news.url}</span>
                  </div>
                  <div>
                    <span className="news-date">Date: {news.date}</span>
                  </div>
                </div>
                <div className="news-actions">
                  <button onClick={() => handleEdit(news)}>Edit</button>
                  <button onClick={() => handleDelete(news.id)}>Delete</button>
                </div>
              </div>
            ))
          ) : (
            <p>No news found.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddNews;
