import React from "react";
import "./Dashboard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox, faStar, faNewspaper } from "@fortawesome/free-solid-svg-icons";

function Dashboard() {
  return (
    <div className="dashboard-container">
      {/* Welcome Section */}
      <div className="welcome-section">
        <h1>Welcome to Mountain Mart Nepal's Trade House CMS</h1>
        <p>
          Manage your products, reviews, news, and more from a single,
          user-friendly platform. Ensure seamless operations and keep your
          content updated.
        </p>
      </div>

      {/* Features Section */}
      <div className="features-section">
        <h2>Features</h2>
        <div className="features-grid">
          {/* Manage Products Card */}
          <div className="feature-card">
            <FontAwesomeIcon icon={faBox} className="feature-icon" />
            <h3>Manage Products</h3>
            <p>Add, edit, and organize your product catalog with ease.</p>
          </div>

          {/* Reviews Card */}
          <div className="feature-card">
            <FontAwesomeIcon icon={faStar} className="feature-icon" />
            <h3>Reviews</h3>
            <p>View and manage customer feedback to improve your services.</p>
          </div>

          {/* News & Events Card */}
          <div className="feature-card">
            <FontAwesomeIcon icon={faNewspaper} className="feature-icon" />
            <h3>News & Events</h3>
            <p>Keep your audience informed with the latest updates.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
