import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  FaUsers,
  FaSignOutAlt,
  FaTag,
  FaHome,
  FaList,
  FaStar,
  FaShoppingBag,
  FaNewspaper,
} from "react-icons/fa";

import "./Sidebar.scss";

function Sidebar() {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("auth");
    navigate("/login");
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <div className="sidebar-top">
        <h2 className="admin-panel">Admin Panel</h2>
        <div className="hr-line"></div>
        <nav>
          <div className="section-label">Main</div>
          <ul>
            <li>
              {/* Dashboard link */}
              <NavLink
                to="/admin/dashboard"
                className={({ isActive }) => (isActive ? "active" : "")}
                end
              >
                <FaHome className="icon" />
                <p className="names-cat">Dashboard</p>
              </NavLink>
            </li>
          </ul>
          <div className="section-label">General</div>
          <ul>
            <li>
              <NavLink
                to="/admin/add-category"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <FaList className="icon" />
                <p className="names-cat">Category</p>
              </NavLink>
              <NavLink
                to="/admin/add-cproduct"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <FaTag className="icon" />
                <p className="names-cat">Category Product</p>
              </NavLink>
              <NavLink
                to="/admin/add-product"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <FaShoppingBag className="icon" />
                <p className="names-cat">Product</p>
              </NavLink>
              <NavLink
                to="/admin/add-review"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <FaStar className="icon" />
                <p className="names-cat">Review</p>
              </NavLink>
              <NavLink
                to="/admin/add-news"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <FaNewspaper className="icon" />
                <p className="names-cat">News</p>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <button onClick={handleLogout} className="logout-button">
        <FaSignOutAlt className="icon" />
        <span className="logout-name">Logout</span>
      </button>
    </div>
  );
}

export default Sidebar;
