import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ProductCategory.scss"; // Ensure this SCSS file matches the styles

const API_URL = process.env.REACT_APP_API_URL; // Fetch API URL from .env

function AddProductCategory() {
  const [title, setTitle] = useState(""); // Product category title
  const [quantity, setQuantity] = useState(""); // Product quantity
  const [description, setDescription] = useState(""); // Product description
  const [image, setImage] = useState(null); // For handling the image
  const [productCategories, setProductCategories] = useState([]); // All product categories
  const [categories, setCategories] = useState([]); // All categories (Parent categories)
  const [categoryId, setCategoryId] = useState(""); // ID of the parent category
  const [editId, setEditId] = useState(null); // To handle editing of a specific product category

  useEffect(() => {
    fetchProductCategories();
    fetchCategories(); // Fetch categories for dropdown
  }, []);

  // Fetch all product categories from the backend
  const fetchProductCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/product_categories`);
      setProductCategories(response.data);
    } catch (error) {
      console.error("Error fetching product categories:", error.message);
      alert("Failed to fetch product categories. Please try again.");
    }
  };

  // Fetch all categories for dropdown
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/categories`);
      setCategories(response.data); // Set categories from the response
    } catch (error) {
      console.error("Error fetching categories:", error.message);
      alert("Failed to fetch categories. Please try again.");
    }
  };

  // Handle form submission for adding/updating product categories
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("quantity", quantity);
    formData.append("description", description);
    formData.append("category_id", categoryId);

    // Append the image file to FormData
    if (image) {
      formData.append("image", image);
    }

    try {
      if (editId) {
        await axios.put(
          `${API_URL}/product_categories/update/${editId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        alert("Product category updated successfully!");
      } else {
        await axios.post(`${API_URL}/product_categories/add`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Product category added successfully!");
      }
      fetchProductCategories(); // Refresh the product categories list
      resetForm(); // Reset the form after save
    } catch (error) {
      console.error("Error saving product category:", error.message);
      alert("Failed to save product category. Please try again.");
    }
  };

  // Handle product category deletion
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/product_categories/delete/${id}`);
      fetchProductCategories();
    } catch (error) {
      console.error("Error deleting product category:", error.message);
      alert("There was an error deleting the product category!");
    }
  };

  // Handle edit product category
  const handleEdit = (productCategory) => {
    setTitle(productCategory.title);
    setQuantity(productCategory.quantity);
    setDescription(productCategory.description);
    setCategoryId(productCategory.category_id); // Set the selected parent category ID
    setEditId(productCategory.id); // Set editId to handle editing
  };

  // Reset form fields
  const resetForm = () => {
    setTitle("");
    setQuantity("");
    setDescription("");
    setImage(null); // Reset image to null
    setCategoryId("");
    setEditId(null); // Reset the form
  };

  // Handle file change for image upload
  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className="product-category-admin-panel">
      <div className="add-product-category">
        <h1 className="title-category">
          {editId ? "Update Product Category" : "Add Product Category"}
        </h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Title:</label>
            <input
              className="input-category"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Category Title"
              required
            />
          </div>
          <div>
            <label>Quantity:</label>
            <input
              className="input-category"
              type="text"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              placeholder="Quantity"
              required
            />
          </div>
          <div>
            <label>Description:</label>
            <textarea
              className="input-category"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
            ></textarea>
          </div>

          <div>
            <label>Image:</label>
            <input type="file" onChange={handleFileChange} />
          </div>
          <div>
            <label>Parent Category:</label>
            <select
              className="input-category"
              value={categoryId}
              onChange={(e) => setCategoryId(e.target.value)}
              required
            >
              <option value="">Select Parent Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <button className="button-add-category" type="submit">
            {editId ? "Update Product Category" : "Add Product Category"}
          </button>
        </form>
      </div>

      <div className="product-categories-container">
        <h2 className="title-category-result">Product Categories List</h2>
        {productCategories && productCategories.length > 0 ? (
          productCategories.map((productCategory) => (
            <div key={productCategory.id} className="category-card">
              <div className="category-info">
                {/* Display the image if it exists */}
                {productCategory.image && (
                  <img
                    src={`${API_URL}${productCategory.image}`}
                    alt={productCategory.title}
                    className="category-image"
                  />
                )}
                <div>
                  <span className="category-name">{productCategory.title}</span>
                </div>
                <div>
                  <span className="category-quantity">
                    Quantity: {productCategory.quantity}
                  </span>
                </div>
              </div>
              <div className="category-actions">
                <button onClick={() => handleEdit(productCategory)}>
                  Edit
                </button>
                <button onClick={() => handleDelete(productCategory.id)}>
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No product categories found.</p>
        )}
      </div>
    </div>
  );
}

export default AddProductCategory;
