import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Category.scss"; // Ensure this SCSS file matches the styles

const API_URL = process.env.REACT_APP_API_URL; // Fetch API URL from .env

function AddCategory() {
  const [title, setTitle] = useState(""); // Category title (ENUM values like 'Top Categories', 'Gift', etc.)
  const [name, setName] = useState(""); // Category name (actual name of the category)
  const [description, setDescription] = useState(""); // Category description
  const [image, setImage] = useState(null); // Image file for the category
  const [categories, setCategories] = useState([]); // All categories from the backend
  const [editId, setEditId] = useState(null); // To handle editing of a specific category

  useEffect(() => {
    fetchCategories();
  }, []);

  // Fetch all categories from the backend
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
      alert("Failed to fetch categories. Please try again.");
    }
  };

  // Handle form submission for adding/updating categories
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(); // Create form data object for image upload
    formData.append("name", name);
    formData.append("title", title);
    formData.append("description", description); // Include description in the form data

    // Append the image file if selected
    if (image) {
      formData.append("image", image);
    }

    try {
      if (editId) {
        await axios.put(`${API_URL}/categories/update/${editId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }); // For updating a category
      } else {
        await axios.post(`${API_URL}/categories/add`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }); // For adding a new category
      }
      alert("Category saved successfully!");
      fetchCategories(); // Refresh the categories list
      resetForm(); // Reset the form after save
    } catch (error) {
      console.error("Error saving category:", error.message);
      alert("Failed to save category. Please try again.");
    }
  };

  // Handle category deletion
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/categories/delete/${id}`);
      fetchCategories(); // Refresh the category list
    } catch (error) {
      console.error("Error deleting category:", error.message);
      alert("There was an error deleting the category!");
    }
  };

  // Handle edit category
  const handleEdit = (category) => {
    setTitle(category.title);
    setName(category.name);
    setDescription(category.description); // Set the description during edit
    setEditId(category.id); // Set editId to handle editing
  };

  // Reset form fields
  const resetForm = () => {
    setTitle("");
    setName("");
    setDescription(""); // Reset description
    setImage(null); // Reset image field
    setEditId(null); // Reset the form
  };

  // Handle file input change (for image upload)
  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className="category-admin-panel">
      <div className="add-category">
        <h1 className="title-category">
          {editId ? "Update Category" : "Add Category"}
        </h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Title:</label>
            <select
              className="input-category"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            >
              <option value="">Select a Title</option>
              <option value="Top Categories">Top Categories</option>
              <option value="Gift">Gift</option>
              <option value="Services">Services</option>
            </select>
          </div>
          <div>
            <label>Category Name:</label>
            <input
              className="input-category"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Category Name"
              required
            />
          </div>
          <div>
            <label>Category Description:</label>
            <textarea
              className="input-category"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Category Description"
              required
            ></textarea>
          </div>
          <div>
            <label>Category Image:</label>
            <input type="file" onChange={handleFileChange} />
          </div>
          <button className="button-add-category" type="submit">
            {editId ? "Update Category" : "Add Category"}
          </button>
        </form>
      </div>

      <div className="categories-container">
        <h2 className="title-category-result">Categories List</h2>
        {categories && categories.length > 0 ? (
          categories.map((category) => (
            <div key={category.id} className="category-card">
              <div className="category-info">
                {/* Display category image */}
                {category.image && (
                  <img
                    className="category-image"
                    src={`${API_URL}${category.image}`}
                    alt={category.name}
                    width="100"
                  />
                )}
                <div>
                  <span className="category-name">{category.name}</span>
                </div>
                <div>
                  <span className="category-title">{category.title}</span>
                </div>
                <div>
                  {/* <span className="category-description">
                    {category.description}
                  </span> */}
                </div>
              </div>
              <div className="category-actions">
                <button onClick={() => handleEdit(category)}>Edit</button>
                <button onClick={() => handleDelete(category.id)}>
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No categories found.</p>
        )}
      </div>
    </div>
  );
}

export default AddCategory;
