import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Product.scss"; // Ensure this SCSS file matches the styles

const API_URL = process.env.REACT_APP_API_URL; // Fetch API URL from .env

function AddProduct() {
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(null);
  const [productCategories, setProductCategories] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [products, setProducts] = useState([]);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchProductCategories();
    fetchProducts();
  }, []);

  const fetchProductCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/product_categories`);
      setProductCategories(response.data);
    } catch (error) {
      console.error("Error fetching product categories:", error.message);
      alert("Failed to fetch product categories. Please try again.");
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${API_URL}/products`);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error.message);
      alert("Failed to fetch products. Please try again.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("quantity", quantity);
    formData.append("price", price);
    formData.append("product_category_id", categoryId);

    if (image) {
      formData.append("image", image);
    }

    try {
      if (editId) {
        await axios.put(`${API_URL}/products/update/${editId}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        alert("Product updated successfully!");
      } else {
        await axios.post(`${API_URL}/products/add`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        alert("Product added successfully!");
      }
      fetchProducts();
      resetForm();
    } catch (error) {
      console.error("Error saving product:", error.message);
      alert("Failed to save product. Please try again.");
    }
  };

  const handleFileChange = (e) => setImage(e.target.files[0]);

  const resetForm = () => {
    setName("");
    setQuantity("");
    setPrice("");
    setImage(null);
    setCategoryId("");
    setEditId(null);
  };

  const handleEdit = (product) => {
    setName(product.name);
    setQuantity(product.quantity);
    setPrice(product.price);
    setCategoryId(product.product_category_id);
    setEditId(product.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/products/delete/${id}`);
      fetchProducts();
    } catch (error) {
      console.error("Error deleting product:", error.message);
      alert("Failed to delete product.");
    }
  };

  return (
    <div className="product-container">
      <div className="card-container">
        {/* Add/Update Product Card */}
        <div className="product-card-entry">
          <h2>{editId ? "Update Product" : "Add Product"}</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Name:</label>
              <input
                className="input-product"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Product Name"
                required
              />
            </div>

            <div>
              <label>Quantity:</label>
              <input
                className="input-product"
                type="text"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                placeholder="Quantity"
                required
              />
            </div>

            <div>
              <label>Price:</label>
              <input
                className="input-product"
                type="text"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder="Price"
                required
              />
            </div>

            <div>
              <label>Image:</label>
              <input type="file" onChange={handleFileChange} />
            </div>

            <div>
              <label>Category:</label>
              <select
                className="input-product"
                value={categoryId}
                onChange={(e) => setCategoryId(e.target.value)}
                required
              >
                <option value="">Select Category</option>
                {productCategories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.title}
                  </option>
                ))}
              </select>
            </div>

            <button className="button-add-product" type="submit">
              {editId ? "Update Product" : "Add Product"}
            </button>
          </form>
        </div>

        {/* Display Product List Card */}
        <div className="product-card-display">
          <h2>Product List</h2>
          {products && products.length > 0 ? (
            products.map((product) => (
              <div key={product.id} className="product-display-item">
                <div className="product-info">
                  {product.image && (
                    <img
                      src={`${API_URL}${product.image}`}
                      alt={product.name}
                      className="product-image"
                    />
                  )}
                  <div>
                    <span className="product-name">{product.name}</span>
                  </div>
                  <div>
                    <span className="product-quantity">
                      Quantity: {product.quantity}
                    </span>
                  </div>
                  <div>
                    <span className="product-price">
                      Price: {product.price}
                    </span>
                  </div>
                </div>
                <div className="product-actions">
                  <button onClick={() => handleEdit(product)}>Edit</button>
                  <button onClick={() => handleDelete(product.id)}>
                    Delete
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No products found.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
